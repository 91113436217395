import React from 'react';
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { cn } from '@/lib/utils';
import { getPublicImage } from '@/utils/image';
import Image from 'next/image';
import { Wand2 } from 'lucide-react';
import 'swiper/css';
import 'swiper/css/pagination';

interface CustomBannerCarouselProps {
  bannerId?: string;
}

export const CustomBannerCarousel: React.FC<CustomBannerCarouselProps> = ({ bannerId }) => {
  const router = useRouter();
  
  const pagination = {
    clickable: true,
    dynamicBullets: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="w-2 h-2 sm:w-3 sm:h-3 md:w-4 md:h-4 border border-pink cursor-pointer ' + className + '">' + '</span>';
    },
  };

  const banners = [
    {
      id: 'ai-girlfriend',
      title: 'Create your own AI Girlfriend',
      features: [
        'Customize your virtual companion\'s look & personality',
        '24/7 uncensored conversations',
        'Generate spicy pictures and receive voice msgs'
      ],
      buttonText: 'Create AI Girlfriend',
      mobileButtonText: 'Create AI Girlfriend',
      backgroundClass: 'bg-gradient-to-r from-[#0a0a0a] via-[#121212] to-[#1a1a1a]',
      imageUrls: [
        'https://images.thotchat.ai/banners/girlfriend-left.png',
        'https://images.thotchat.ai/banners/girlfriend-center.png',
        'https://images.thotchat.ai/banners/girlfriend-right-v2.png'
      ],
      altTexts: [
        'AI Girlfriend character with customizable appearance',
        'Create your perfect AI Girlfriend companion',
        'AI Girlfriend character with personalized features'
      ]
    },
    {
      id: 'ai-anime',
      title: 'Create your own AI Waifu',
      features: [
        'Design your perfect AI hentai companion',
        'Roleplay any scenario you can imagine',
        'Generate spicy images and voice messages'
      ],
      buttonText: 'Create AI Waifu',
      mobileButtonText: 'Create AI Waifu',
      backgroundClass: 'bg-gradient-to-r from-[#0a0a0a] via-[#121212] to-[#1a1a1a]',
      imageUrls: [
        'https://images.thotchat.ai/banners/anime-left.png',
        'https://images.thotchat.ai/banners/anime-center.png',
        'https://images.thotchat.ai/banners/anime-right.png'
      ],
      altTexts: [
        'Anime-style AI character with customizable features',
        'Create your perfect anime AI companion',
        'Personalized anime AI character with custom styling'
      ]
    },
    {
      id: 'ai-boyfriend',
      title: 'Create your own AI Boyfriend',
      features: [
        'Design his look & personality',
        '24/7 uncensored conversations',
        'Get custom pictures and voice messages'
      ],
      buttonText: 'Create AI Boyfriend',
      mobileButtonText: 'Create AI Boyfriend',
      backgroundClass: 'bg-gradient-to-r from-[#0a0a0a] via-[#121212] to-[#1a1a1a]',
      imageUrls: [
        'https://images.thotchat.ai/banners/boyfriend-left.png',
        'https://images.thotchat.ai/banners/boyfriend-center.png',
        'https://images.thotchat.ai/banners/boyfriend-right.png'
      ],
      altTexts: [
        'AI Boyfriend character with customizable appearance',
        'Create your perfect AI Boyfriend companion',
        'AI Boyfriend character with personalized features'
      ]
    },
    {
      id: 'ai-futa',
      title: 'Create your own AI Futa',
      features: [
        'Design your virtual AI Futa companion',
        'Explore any scenario you desire',
        'Get custom images and voice msgs'
      ],
      buttonText: 'Create AI Futa',
      mobileButtonText: 'Create AI Futa',
      backgroundClass: 'bg-gradient-to-r from-[#0a0a0a] via-[#121212] to-[#1a1a1a]',
      imageUrls: [
        'https://images.thotchat.ai/banners/futa-left.png',
        'https://images.thotchat.ai/banners/futa-center.png',
        'https://images.thotchat.ai/banners/futa-right.png'
      ],
      altTexts: [
        'AI Futa character with customizable appearance',
        'Create your perfect AI Futa companion',
        'AI Futa character with personalized features'
      ]
    }
  ];

  // Filter banners if a specific bannerId is provided
  const displayBanners = bannerId 
    ? banners.filter(banner => banner.id === bannerId)
    : banners;

  return (
    <div className="relative -mx-4 sm:-mx-6 md:-mx-10 lg:-mx-12 w-screen ml-[calc(-50vw+50%)] mt-0 md:mt-0">
      {/* Structured data for SEO */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": displayBanners.length > 1 ? "ItemList" : "WebPage",
            ...(displayBanners.length > 1 
              ? {
                  "itemListElement": displayBanners.map((banner, index) => ({
                    "@type": "ListItem",
                    "position": index + 1,
                    "item": {
                      "@type": "WebPage",
                      "name": banner.title,
                      "description": banner.features.join(". "),
                      "image": banner.imageUrls[1],
                      "url": banner.id === 'ai-girlfriend' ? '/v2/character/create?step=0' : 
                            banner.id === 'ai-anime' ? '/v2/character/create?step=0&style=artistic' : 
                            banner.id === 'ai-boyfriend' ? '/v2/character/create?step=0&gender=male' : 
                            '/v2/character/create?step=0&gender=futa'
                    }
                  }))
                }
              : {
                  "name": displayBanners[0].title,
                  "description": displayBanners[0].features.join(". "),
                  "image": displayBanners[0].imageUrls[1],
                  "url": displayBanners[0].id === 'ai-girlfriend' ? '/v2/character/create?step=0' : 
                        displayBanners[0].id === 'ai-anime' ? '/v2/character/create?step=0&style=artistic' : 
                        displayBanners[0].id === 'ai-boyfriend' ? '/v2/character/create?step=0&gender=male' : 
                        '/v2/character/create?step=0&gender=futa'
                }
            )
          })
        }}
      />
      
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={displayBanners.length > 1}
        spaceBetween={0}
        slidesPerView={1}
        pagination={displayBanners.length > 1 ? pagination : false}
        modules={[Pagination, Autoplay]}
        className="w-full"
      >
        {displayBanners.map((banner) => (
          <SwiperSlide key={banner.id}>
            <div 
              className={`relative w-full h-[162px] sm:h-[200px] md:h-[250px] lg:h-[300px] overflow-hidden ${banner.backgroundClass}`}
              aria-labelledby={`banner-title-${banner.id}`}
            >
              {/* CSS-based background pattern */}
              <div className="absolute inset-0 opacity-40">
                <div className="absolute inset-0 bg-[radial-gradient(#444_1px,transparent_1px)] [background-size:20px_20px]"></div>
              </div>
              
              
              {/* Content */}
              <div className="absolute inset-0 flex flex-row items-center justify-between px-4 sm:px-6 md:px-8 lg:px-12 max-w-[1400px] mx-auto">
                {/* Left side text content */}
                <div className="text-white z-10 w-2/3 sm:w-3/4 md:w-[55%] lg:w-[60%]">
                  <h1 id={`banner-title-${banner.id}`} className="font-heading text-h2 sm:text-h1 md:text-[36px] lg:text-[42px] mb-1 md:mb-2 leading-tight md:leading-tight lg:leading-tight font-bold md:pr-4 lg:pr-8">
                    {banner.id === 'ai-girlfriend' && (
                      <>
                        <span className="text-white whitespace-nowrap md:inline">Create your </span>
                        <span className="text-pink block sm:inline whitespace-nowrap">AI Girlfriend</span>
                      </>
                    )}
                    {banner.id === 'ai-anime' && (
                      <>
                        <span className="text-white whitespace-nowrap md:inline">Create your own </span>
                        <span className="text-pink block sm:inline whitespace-nowrap">AI Hentai</span>
                      </>
                    )}
                    {banner.id === 'ai-boyfriend' && (
                      <>
                        <span className="text-white whitespace-nowrap md:inline">Create your </span>
                        <span className="text-pink block sm:inline whitespace-nowrap">AI Boyfriend</span>
                      </>
                    )}
                    {banner.id === 'ai-futa' && (
                      <>
                        <span className="text-white whitespace-nowrap md:inline">Create your </span>
                        <span className="text-pink block sm:inline whitespace-nowrap">AI Futa</span>
                      </>
                    )}
                  </h1>
                  <div className="hidden sm:block space-y-0 md:space-y-1 lg:space-y-1 pl-1 md:pl-2">
                    {banner.features.map((feature, index) => (
                      <p key={index} className="font-body text-body-2 sm:text-body-2 md:text-body-1 lg:text-body-1 text-white/80 leading-tight sm:leading-normal">{feature}</p>
                    ))}
                  </div>
                  <div className="group/button relative rounded-xl border border-transparent inline-block mt-2 md:mt-4 lg:mt-6">
                    <a 
                      href={banner.id === 'ai-girlfriend' ? '/v2/character/create?step=0' : 
                            banner.id === 'ai-anime' ? '/v2/character/create?step=0&style=artistic' : 
                            banner.id === 'ai-boyfriend' ? '/v2/character/create?step=0&gender=male' : 
                            '/v2/character/create?step=0&gender=futa'}
                      className="inline-flex items-center bg-pink-gr text-white font-button py-3 sm:py-2 md:py-3 lg:py-4 px-6 sm:px-6 md:px-8 lg:px-10 rounded-xl text-btn-2 sm:text-btn-2 md:text-btn-1 lg:text-btn-1 transition-all duration-300 relative z-10 -translate-x-[3px] -translate-y-[3px] group-hover/button:translate-x-[0] group-hover/button:translate-y-[0]"
                      aria-label={`${banner.buttonText} - ${banner.features[0]}`}
                      title={banner.buttonText}
                    >
                      <Wand2 className="w-4 h-4 sm:w-4 sm:h-4 md:w-5 md:h-5 mr-2 sm:mr-2" />
                      <span className="hidden md:inline">{banner.buttonText}</span>
                      <span className="inline md:hidden text-[110%]">{banner.mobileButtonText}</span>
                    </a>
                    <div className="block z-0 w-full h-full absolute left-[0] top-[0] bg-black rounded-xl"></div>
                  </div>
                </div>
                
                {/* Right side images - hidden on small screens, visible on medium and up */}
                <div className="hidden md:flex items-end justify-end space-x-[-40px] h-full w-[50%] md:w-[40%] lg:w-[35%]">
                  {banner.imageUrls.map((url, index) => (
                    <div 
                      key={index} 
                      className={cn(
                        'h-auto relative',
                        index === 1 ? 'w-[160px] lg:w-[240px] z-20' : 'w-[145px] lg:w-[220px] z-10',
                        'animate-float',
                        index === 0 ? 'animation-delay-0' : index === 1 ? 'animation-delay-1000' : 'animation-delay-2000'
                      )}
                      style={{
                        height: '95%',
                      }}
                    >
                      <Image
                        src={url}
                        alt={banner.altTexts[index]}
                        fill
                        style={{
                          objectFit: 'contain',
                          objectPosition: 'bottom',
                          transform: `${index === 0 ? 'translateX(20px)' : index === 2 ? 'translateX(-20px)' : 'scale(1.05)'}`,
                          transformOrigin: 'bottom'
                        }}
                        priority={index === 1}
                      />
                    </div>
                  ))}
                </div>
                
                {/* Single image for mobile - only visible on small screens */}
                <div className="absolute right-0 top-0 bottom-0 w-[45%] md:hidden overflow-hidden">
                  <div className="relative w-full h-full">
                    <Image
                      src={banner.imageUrls[1]} // Only using center image (index 1)
                      alt={banner.altTexts[1]}
                      fill
                      style={{
                        objectFit: 'contain',
                        objectPosition: 'bottom right',
                        opacity: 1,
                      }}
                      priority
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      
      {/* Custom pagination container outside of the slides */}
      <div className="banner-pagination absolute bottom-1 sm:bottom-2 md:bottom-3 left-0 right-0 z-20 flex justify-center"></div>
      
      {/* Custom CSS to override Swiper's default pagination styles */}
      <style jsx global>{`
        .swiper-pagination-bullet {
          background: transparent !important;
          opacity: 1 !important;
          cursor: pointer;
        }
        .swiper-pagination-bullet-active {
          background: #ec4899 !important;
        }
        
        @keyframes float {
          0% {
            transform: scale(1) rotate(0deg);
          }
          50% {
            transform: scale(1.02) rotate(0.5deg);
          }
          100% {
            transform: scale(1) rotate(0deg);
          }
        }
        
        .animate-float {
          animation: float 2s ease-in-out infinite;
          transform-origin: bottom;
        }
        
        .animation-delay-0 {
          animation-delay: 0s;
        }
        
        .animation-delay-1000 {
          animation-delay: 0.67s;
        }
        
        .animation-delay-2000 {
          animation-delay: 1.33s;
        }
      `}</style>
      
      {/* Additional SEO metadata */}
      <div className="sr-only">
        <h2>AI Character Creation Options</h2>
        <p>Create custom AI characters for chat, roleplay, and companionship. Choose from AI girlfriends, boyfriends, anime characters, and more.</p>
        <ul>
          <li><a href="/v2/character/create?step=0">Create AI Girlfriend</a></li>
          <li><a href="/v2/character/create?step=0&style=artistic">Create AI Hentai Character</a></li>
          <li><a href="/v2/character/create?step=0&gender=male">Create AI Boyfriend</a></li>
          <li><a href="/v2/character/create?step=0&gender=futa">Create AI Futa Character</a></li>
        </ul>
      </div>
    </div>
  );
}; 