import { useState, useEffect } from 'react';
import { BaseModal, closeModal } from '@/components/BaseModal';
import { Button } from '@/components/buttons/Button';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import { useCharactersStore } from "@/stores/characters";
import { cn } from "@/lib/utils";
import { Text } from '@/components/Text';
import { Modals } from '@/types/modals';
import CloseIcon from '@/assets/icons/close-icon.svg';
import { CharacterFilterTags, CharacterSortTags, CharacterSortType } from '@/models/characters';

export function FilterModal({ isVisible, onClose }: { isVisible?: boolean; onClose?: () => void }) {
  const { filterTagsSelected, setFilterTagsSelected, sortTagSelected, setSortTagSelected } = useCharactersStore();
  const [updatedFilters, setUpdatedFilters] = useState<string[]>([]);
  const [updatedSort, setUpdatedSort] = useState(sortTagSelected);

  const tagsByCategory = CharacterFilterTags.map(filter => filter.tag).filter(Boolean).reduce((acc: {[key: string]: string[]}, tag) => { 
    if (!tag) return acc;
    const [category] = tag.split(':');
    if (!acc[category]) acc[category] = [];
    acc[category].push(tag);
    return acc;
  }, {});

  // Update local state when modal opens
  useEffect(() => {
    setUpdatedFilters([...filterTagsSelected]);
    setUpdatedSort(sortTagSelected);
  }, [filterTagsSelected, sortTagSelected]);

  const handleClose = () => {
    closeModal(Modals.Filter);
    onClose?.();
  };

  const toggleFilter = (tag: string) => {
    if (updatedFilters.includes(tag)) { 
      setUpdatedFilters(updatedFilters.filter(t => t !== tag));
    } else {
      setUpdatedFilters([...updatedFilters, tag]);
    }
  };

  const handleResetFilters = () => {
    // Clear all filters
    setUpdatedFilters([]);
    // Reset sort to default (Popular)
    setUpdatedSort(CharacterSortType.POPULAR);
  };
  
  const handleApplyFilters = () => {
    // Apply the updated filters to the global state
    setFilterTagsSelected(updatedFilters);
    setSortTagSelected(updatedSort);
    handleClose();
  };

  return (
    <BaseModal
      className="backdrop-filter backdrop-blur-md bg-black bg-opacity-50"
      id={Modals.Filter}
      onClose={handleClose}
    >
      <div className="flex flex-col border border-gray-700 bg-black-100 rounded-3xl overflow-hidden max-w-[425px] max-h-[90vh] w-[calc(100vw-20px)]">
        <div className="flex flex-col relative w-full px-6 pt-6 pb-4">
          <button
            onClick={handleClose}
            className="absolute right-2 top-2 rounded-full bg-white/[0.25] p-1 ring-offset-background transition-opacity hover:opacity-100 hover:bg-white/[0.5] disabled:pointer-events-none"
            aria-label="Close"
          >
            <CloseIcon />
          </button>

          <Text className="text-white text-2xl" variant="h3">
            Sorting & Filters
          </Text>
        </div>

        <div className="flex w-full border-t border-white/25"/>

        <div className="flex flex-col relative w-full p-6">

          <div className="space-y-4 overflow-y-auto">
            <div className="space-y-4 mb-8">
              <Text className="text-lg font-medium text-white" variant="body-2">Sort by</Text>
              <div className="w-[250px] rounded-lg">
                <Select
                    value={{ 
                      value: updatedSort, 
                      label: CharacterSortTags.find(tag => tag.id === updatedSort)?.name,
                      icon: CharacterSortTags.find(tag => tag.id === updatedSort)?.icon
                    }}
                    onChange={(option) => setUpdatedSort(option?.value || '')}
                    options={CharacterSortTags.map(tag => ({
                      value: tag.id,
                      label: tag.name,
                      icon: tag.icon
                    }))}
                    components={{
                        Option: ({ children, ...props }: OptionProps<any>) => {
                            const { icon: Icon } = props.data;
                            return (
                            <components.Option {...props}>
                                <div className="flex items-center gap-2">
                                {Icon && <Icon className="w-4 h-4" />}
                                {children}
                                </div>
                            </components.Option>
                            );
                        },

                        SingleValue: ({ children, ...props }: SingleValueProps<any>) => {
                            const { icon: Icon } = props.data;
                            return (
                            <components.SingleValue {...props}>
                                <div className="flex items-center gap-2">
                                    {Icon && <Icon className="w-4 h-4" />}
                                    {children}
                                </div>
                            </components.SingleValue>
                            );
                        }
                    }}
                    styles={{
                    control: (base) => ({
                        ...base,
                        backgroundColor: '#1A1A1A', // bg-black-200
                        border: '1px solid rgba(255, 255, 255, 0.25)', // Add border with 25% white opacity
                        borderRadius: '0.5rem', // rounded-xl
                        boxShadow: 'none',
                        '&:hover': {
                            border: '1px solid rgba(255, 255, 255, 1.0)'
                        }
                    }),
                    menu: (base) => ({
                        ...base,
                        backgroundColor: '#1A1A1A',
                        zIndex: 9999,
                        position: 'absolute',
                        border: '1px solid rgba(255, 255, 255, 0.25)',
                        borderRadius: '0.75rem',
                        overflow: 'hidden'  
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: 5,
                        borderRadius: '0.5rem', // rounded-xl
                    }),
                    option: (base, { isFocused, isSelected }) => ({
                        ...base,
                        backgroundColor: isSelected 
                          ? 'rgba(255, 255, 255, 0.35)' 
                          : isFocused 
                            ? '#1A1A1A' // bg-black-200
                            : '#1A1A1A', // bg-black-200
                        color: isSelected 
                          ? 'white' 
                          : isFocused 
                            ? 'white' 
                            : 'rgba(255, 255, 255, 0.7)',
                        cursor: 'pointer',
                        padding: '8px 12px',
                        margin: '2px 0', // Add space between options
                        borderRadius: '0.5rem', // Rounded corners for each option
                        transition: 'all 0.2s ease',
                        '&:active': {
                          backgroundColor: 'rgba(255, 255, 255, 0.35)'
                        },
                        '&:hover': {
                          backgroundColor: '#2A2A2A',
                          color: 'white'
                        }
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: 'rgba(255, 255, 255, 0.7)', // 70% opacity for selected value
                        '&:hover': {
                            color: 'white' // White text on hover
                        }
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: 'white'
                    }),
                    input: (base) => ({
                        ...base,
                        color: 'white'
                    })
                    }}
                />
              </div>
            </div>

            <Text className="text-lg font-medium text-white" variant="body-2">Filters</Text>
            <div className="space-y-6 rounded-lg">
              
              {Object.entries(tagsByCategory).map(([category, tags]) => (
                <div key={category} className="space-y-2">
                  <Text className="text-sm text-white/70 capitalize" variant="body-2">
                    {category}
                  </Text>
                  <div className="flex flex-wrap gap-2">
                    {tags.map(tag => {
                      const filterObj = CharacterFilterTags.find(f => f.tag === tag);
                      return (
                        <button
                          key={tag}
                          onClick={() => toggleFilter(tag)}
                          className={cn(
                            'px-3 py-1.5 rounded-lg text-sm transition-all items-center justify-center text-center',
                            updatedFilters.includes(tag)
                              ? 'bg-white/35 border-white/50 border-[1px] text-white'
                              : 'bg-black-200 text-gray-300 hover:text-white'
                          )}
                        >
                          {filterObj?.icon && <filterObj.icon className="w-4 h-4 inline mr-1" />}
                          {filterObj?.name || tag.split(':')[1]}
                        </button>
                      )}
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex mt-2 mb-2 w-full border-t border-white/25"/>
        
        <div className="flex p-6 justify-between pt-4 border-gray-700">
        <Button
            onClick={handleResetFilters}
            className="w-[48%]"
            wrapperClass="py-[10px] bg-black-200"
        >
            <Text
            className="transition-all duration-150"
            textColor="white"
            variant="btn-1"
            >
            Clear Filters
            </Text>
        </Button>
        <Button
            onClick={handleApplyFilters}
            className="w-[48%]"
            wrapperClass="py-[10px] bg-pink"
        >
            <Text
            className="transition-all duration-150"
            textColor="white"
            variant="btn-1"
            >
            Apply Filters
            </Text>
        </Button>
        </div>
      </div>
    </BaseModal>
  );
} 